const API_DOMAIN =
  process.env.NEXT_PUBLIC_MODE === 'prod'
    ? 'https://apis.socar.kr'
    : 'https://apis.socar.me'

const MOCK_API_DOMAIN = 'http://localhost:8080'

export const settings = {
  API_DOMAIN,
  MOCK_API_DOMAIN,
}
